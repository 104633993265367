import React, { useState, useEffect, useRef } from "react";
// import Modal from "react-bootstrap/Modal";
import { Navigation, Pagination} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/images/logo.png";
import slider from "../../assets/images/slider.png";
import user from "../../assets/images/user.jpg";
import user1 from "../../assets/images/user1.jpg";
import user2 from "../../assets/images/user2.jpg";
import user3 from "../../assets/images/user3.jpg";
import icon from "../../assets/images/icon.png";
import icon1 from "../../assets/images/icon1.png";
import icon2 from "../../assets/images/icon2.png";
import work from "../../assets/images/work.png";
import work1 from "../../assets/images/work1.png";
import online from "../../assets/images/online.png";
import phone from "../../assets/images/phone.png";
import share from "../../assets/images/share.png";
import work3 from "../../assets/images/work3.png";
import profile from "../../assets/images/profile.png";
import market from "../../assets/images/market.png";
import hash from "../../assets/images/hash.png";
import has1 from "../../assets/images/has1.jpg";
import about from "../../assets/images/about.png";
import aap1 from "../../assets/images/aap1.png";
import aap2 from "../../assets/images/aap2.png";
import connect from "../../assets/images/connect.jpg";
import men from "../../assets/images/men.png";
import dono from "../../assets/images/dono.png";
import { IoStar, IoStarHalfOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { Offcanvas } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import "./Home.css";
import Footer from "../Footers/Footer";
const testimonials = [
  {
    text: "Why is this important? Because clients want to know the businesses they depend on for advice, are well managed in their own right. Not only that but this event gives you the chance to give your back-office team",
    name: "Mahfuz Riad",
    designation: "Ui Designer & CEO",
    imgSrc: "http://t.commonsupport.com/adro/images/resource/thumb-1.jpg",
  },
  {
    text: "Why is this important? Because clients want to know the businesses they depend on for advice, are well managed in their own right. Not only that but this event gives you the chance to give your back-office team",
    name: "Mahfuz Riad",
    designation: "Ui Designer & CEO",
    imgSrc: "http://t.commonsupport.com/adro/images/resource/thumb-1.jpg",
  },
  {
    text: "Why is this important? Because clients want to know the businesses they depend on for advice, are well managed in their own right. Not only that but this event gives you the chance to give your back-office team",
    name: "Mahfuz Riad",
    designation: "Ui Designer & CEO",
    imgSrc: "http://t.commonsupport.com/adro/images/resource/thumb-1.jpg",
  },
];

const HomePage = () => {
  // modal for price
  let location = useLocation();
  const [lgShow, setLgShow] = useState(false);
  // const location = useLocation();
  const [activeSection, setActiveSection] = useState("home");

  const sections = useRef([]);

  const handleScroll = () => {
    // console.log("okay");
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  const handleMenuClick = (sectionId) => {
    // console.log(sectionId, "sectionId");
    const section = document.getElementById(sectionId);
    // console.log("sectiontt", section);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    sections.current = document.querySelectorAll("[data-section]");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const activeStyle = {
    color: "#ac216c",
    borderBottom: "2px solid #ac216c",
    padding: "14px",
  };
  // header off canvas//
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  useEffect(() => {
    if (location.state != null && location.state.id != null) {
      setActiveSection(location.state.id);
      handleMenuClick(location.state.id);
    }
  }, [location]);
  return (
    <>
      {/* desktop header */}
      <header className="desktop-hesder">
        <Navbar collapseOnSelect expand="lg" fixed="top" className="sticky-top">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} className="header-logo" alt="..." />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <ul className="desktop-menu">
                  <li
                    className={
                      activeSection === "about" ? "active" : "inactive"
                    }
                  >
                    <a
                      style={activeSection === "about" ? activeStyle : {}}
                      onClick={() => handleMenuClick("about")}
                    >
                      Who We Are
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "sevices" ? "active" : "inactive"
                    }
                  >
                    <a
                      style={activeSection === "sevices" ? activeStyle : {}}
                      onClick={() => handleMenuClick("sevices")}
                    >
                      Mission
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "feature" ? "active" : "inactive"
                    }
                  >
                    <a
                      style={activeSection === "feature" ? activeStyle : {}}
                      onClick={() => handleMenuClick("feature")}
                    >
                      Feature
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "membership" ? "active" : "inactive"
                    }
                  >
                    <a
                      style={activeSection === "membership" ? activeStyle : {}}
                      onClick={() => handleMenuClick("membership")}
                    >
                      Membership
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "download" ? "active" : "inactive"
                    }
                  >
                    <a
                      style={activeSection === "download" ? activeStyle : {}}
                      onClick={() => handleMenuClick("download")}
                    >
                      Download
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "Contact" ? "active" : "inactive"
                    }
                  >
                    <NavLink
                      to="/contact"
                      style={activeSection === "contact" ? activeStyle : {}}
                      onClick={() => handleMenuClick("contact")}
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Nav>
              <Nav>
                <ul className="desktop-menu">
                  <li>
                    <NavLink to="/login">
                      <FaRegUser className="header-call" />
                      <span>Login</span>
                    </NavLink>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/* mobile view responsive header */}
      <header className="mobile-hesder">
        <Navbar collapseOnSelect expand="lg" fixed="top" className="sticky-top">
          <Container>
            <Navbar.Brand href="#">
              <img src={logo} className="header-logo" alt="..." />
            </Navbar.Brand>
            <Nav>
              <ul className="menu-responivee">
                <li>
                  <NavLink to="/#">
                    <div>
                      <FaRegUser className="header-call" />
                    </div>
                  </NavLink>
                </li>
                <li>
                  <div className="menu">
                    <FiMenu onClick={handleShow} />
                  </div>
                </li>
              </ul>
            </Nav>
          </Container>
        </Navbar>
      </header>
      <Offcanvas
        show={showOffcanvas}
        onHide={handleClose}
        placement="start"
        className={{ width: "35%" }}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="desktop-menu mobile-responsive">
            <li className={activeSection === "about" ? "active" : "inactive"}>
              <a
                style={activeSection === "about" ? activeStyle : {}}
                onClick={() => handleMenuClick("about")}
              >
                About Us
              </a>
            </li>
            <li className={activeSection === "sevices" ? "active" : "inactive"}>
              <a
                style={activeSection === "sevices" ? activeStyle : {}}
                onClick={() => handleMenuClick("sevices")}
              >
                Mission
              </a>
            </li>
            <li className={activeSection === "feature" ? "active" : "inactive"}>
              <a
                style={activeSection === "feature" ? activeStyle : {}}
                onClick={() => handleMenuClick("feature")}
              >
                Feature
              </a>
            </li>
            <li
              className={activeSection === "membership" ? "active" : "inactive"}
            >
              <a
                style={activeSection === "membership" ? activeStyle : {}}
                onClick={() => handleMenuClick("membership")}
              >
                Membership
              </a>
            </li>
            <li
              className={activeSection === "download" ? "active" : "inactive"}
            >
              <a
                style={activeSection === "download" ? activeStyle : {}}
                onClick={() => handleMenuClick("download")}
              >
                Download
              </a>
            </li>
            <li className={activeSection === "contact" ? "active" : "inactive"}>
              <NavLink
                to="/contact"
                style={activeSection === "contact" ? activeStyle : {}}
                onClick={() => handleMenuClick("contact")}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {/* mainheader-section */}

      <section className="Page_1" data-section id="home">
        <Container>
          <div className="">
            <div className="content-right">
              <div className="slide-hesder">
                <img src={slider} />
              </div>
            </div>
            <div className="content-main">
              <div className="text">
                <div className="new-1">
                  <div className="new-0-1">
                    <p className="home-new">Welcome To MeetupsFellow!</p>
                    <h2>
                      Build Your Community Network with a
                      <strong>Strong Platform</strong> 
                    </h2>
                  </div>
                  <h6>
                    At Meetupsfellow, we offer a robust platform designed to
                    connect you with like-minded individuals and help you expand
                    your network. Whether you're looking to make new friends,
                    collaborate with professionals, or engage with your
                    community, our platform provides the tools and support you
                    need to grow and thrive.
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="main-section-hd">
            {/* <Col lg={6} md={6} sm={7} xs={8}> */}
            <div className="text">
              <div className="slider-me">
                <div className="slider-userss">
                  <img src={user} className="user-0" />
                  <img src={user1} className="user-1" />
                  <img src={user2} className="user-2" />
                  <img src={user3} className="user-3" />
                </div>

                <div className="slider-header-text">
                  <p>500k+ Satisfied Clients and Counting!</p>
                </div>
              </div>
            </div>
            {/* <div className="btn_android mt-3">
                <NavLink to="/Registration" className="custom-button">
                  Register Now!
                </NavLink>
              </div> */}
            {/* </Col>
            <Col lg={6} md={6} sm={5} xs={4}> */}

            {/* </Col> */}
          </div>
          <div className="steps-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="steps-section-inner">
                    <div className="myCol1">
                      <h2>3 Easy Steps </h2>
                      <p className="text">To Start a Serious Relationship</p>
                    </div>
                    <div className="myCol2">
                      <ul className="steps-list">
                        <li>
                          <div className="number">1</div>
                          <p className="label">Join</p>
                        </li>
                        <li>
                          <div className="number">2</div>
                          <p className="label">Search</p>
                        </li>
                        <li>
                          <div className="number">3</div>
                          <p className="label">Find People</p>
                        </li>
                      </ul>
                    </div>
                    <div className="myCol3">
                      <a href="#" className="custom-button">
                        Join Now!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* About us section */}
      <div className="about-us" data-section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="about-images">
                <img src={about} />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="abouts mt-4">
                <h6 className="sub-title">Welcome to Meetupsfellow!</h6>
                <h2>
                  <strong>Connect</strong> with more people
                </h2>
                <p>
                  In today’s fast-paced world, MeetUpsFellow is essential for
                  connecting with others. It allows you to browse profiles of
                  nearby singles, engage in conversations, arrange events, and
                  perhaps even find love.
                </p>
                <p>
                  For a straightforward and feature-rich app to meet singles,
                  MeetUpsFellow is your perfect choice. With thousands of users
                  at your fingertips, it offers a seamless and enjoyable
                  experience for exploring, connecting, and finding that special
                  someone.
                </p>
                <div className="about-bt">
                  <a href="#" className="custom-button">
                    Seek Your Ideal Companion
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* services section */}
      <div className="content-103" data-section id="sevices">
        <div className="container">
          <div className="row">
            <div className="services-heading">
              <h6 className="sub-title">Our Aim for the Community</h6>
              <h2>our aim is to foster a vibrant and inclusive community</h2>
              <p>
                We aim to provide a welcoming platform that facilitates
                meaningful interactions, supports diverse interests, and helps
                individuals build lasting relationships.
              </p>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="service-post 1">
                <div className="service-content">
                  <div className="service-icon 1">
                    <img src={icon} className="media-img" />
                    {/* <SiGnusocial/> */}
                  </div>
                  <h3 className="service-title">Fostering Connection</h3>
                  <p className="service-description">
                    We strive to create a platform that brings people together,
                    facilitating meaningful connections and interactions within
                    the community.
                  </p>
                </div>
                <div className="service-hover"></div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon 2">
                    <img src={icon1} className="media-img" />
                  </div>
                  <h3 className="service-title">Supporting Diversity</h3>
                  <p className="service-description">
                    Our goal is to offer a welcoming space for individuals from
                    all walks of life, ensuring that everyone feels included and
                    valued in our community.
                  </p>
                </div>
                <div className="service-hover"></div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon 3">
                    <img src={icon2} className="media-img" />
                  </div>
                  <h3 className="service-title">Encouraging Growth</h3>
                  <p className="service-description">
                    We are dedicated to helping our users grow both personally
                    and professionally. Through our events, discussions, and
                    networking opportunities.
                  </p>
                </div>
                <div className="service-hover"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video section */}
      <section className="industries-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="industries-00">
                <img src={connect} />
                <div
                  className="indtext-0 text-center aos-init aos-animate"
                  data-aos="zoom-in"
                >
                  <h2>Video presentation</h2>
                  <p className="text-center text-white">
                    The sad thing is the majority of people have no clue about
                    what they truly want. They have no clarity. When asked the
                    question, responses will be superficial at best, and at
                    worst, will be what someone else wants for them.
                  </p>
                </div>
                <a id="play-video" className="video-play-button" href="#">
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* feature section */}
      <section className="feature-section" data-section id="feature">
        <img src={men} className="amibg-img" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="content">
                <div className="section-header">
                  <h6 className="sub-title extra-padding wow fadeInUp">
                    An Exhaustive List Of
                  </h6>
                  <h2>Amazing Feature</h2>
                  <p className="text wow fadeInUp">
                    To find meaningful connections, contacts, and partners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content-area">
          <div className="container-fluid">
            <div className="fetutrsee">
              <div className="row fetutre-o">
                <div className=" col-lg-7 col-md-5 col-sm-12">
                  {/* <div className="fet-id">
                    <img
                      className="img1 wow fadeInLeft animated"
                      src={featurs}
                      alt=""
                    />
                  </div> */}
                </div>
                <div className=" col-lg-5 col-md-7 col-sm-12">
                  <div className="feature-lists">
                    <div
                      className="single-feature-list wow fadeInUp first"
                      data-wow-delay="0.1s"
                    >
                      <div className="icon">
                        <img src={work} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">Unlimited Smart Matches</h4>
                        <p>
                          Connect with an endless number of people through our
                          intelligent matching system. Discover new connections
                          effortlessly with smart, personalized recommendations.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-feature-list wow fadeInUp second"
                      data-wow-delay="0.2s"
                    >
                      <div className="icon">
                        <img src={online} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">Build Your Community Network</h4>
                        <p>
                          Create and strengthen a powerful community network on
                          our platform. Connect with like-minded individuals and
                          build meaningful relationships effortlessly.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-feature-list wow fadeInUp third"
                      data-wow-delay="0.3s"
                    >
                      <div className="icon">
                        <img src={phone} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">Chat with Connections</h4>
                        <p>
                          Enjoy secure and user-friendly chat features designed
                          for seamless communication with your connections.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-feature-list wow fadeInUp forth"
                      data-wow-delay="0.4s"
                    >
                      <div className="icon">
                        <img src={work3} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">Community Forum</h4>
                        <p className="">
                          Stay updated with our news feed, featuring the latest
                          updates and discussions in real-time.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-feature-list wow fadeInUp fifth"
                      data-wow-delay="0.1s"
                    >
                      <div className="icon">
                        <img src={share} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">Share Private Media</h4>
                        <p className="">
                          Easily share your media files securely on our
                          platform, ensuring privacy and convenience.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-feature-list wow fadeInUp six"
                      data-wow-delay="0.1s"
                    >
                      <div className="icon">
                        <img src={work1} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">Meetups Events</h4>
                        <p>
                          Expand your social circle and connect with people near
                          and far through our engaging meetup events. Explore
                          opportunities to build new relationships and
                          experiences both locally and globally.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner section */}
      <section className="join-now-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="content">
                <div className="section-header white-color">
                  <h2>Express Yourself in New Ways!</h2>
                  <p className="text-white">
                    Become part of a vibrant community where your voice is
                    heard, your individuality is celebrated, and your
                    connections are meaningful. Sign up today and start
                    expressing yourself in ways you’ve always imagined.
                  </p>
                </div>
                <a href="#" className="custom-button">
                  Join Now!
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <img className="shape2" src={profile} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* membership desktop view section */}
      <section
        className="membership-section dektop-view"
        data-section
        id="membership"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="content-0">
                <div className="section-header">
                  <h6 className="sub-title extra-padding">
                    Upgrade Your Plans
                  </h6>
                  <h2>Membership Plan</h2>
                  <p className="text">
                  Unlock a world of exclusive benefits designed for our most valued members. Enjoy early access to new features, premium content, and personalized support, all crafted to enhance your experience.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="priceee">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="single-plan">
                  <p className="duration">Free</p>
                  <h4 className="number">
                    <sup>$</sup>0
                  </h4>
                  <p className="stamet">N/A</p>
                  <NavLink to="/plans-detail" className="custom-button">
                    Try Now!
                  </NavLink>
                  {/* <h6 className="planss" onClick={() => setLgShow(true)}>
                    Read More
                  </h6> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="single-plan">
                  <p className="duration">Special park</p>
                  <h4 className="number">
                    <sup>$</sup>1.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <NavLink to="/plans-detail" className="custom-button">
                    Buy Now!
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="single-plan">
                  <p className="duration">Standard park</p>
                  <h4 className="number">
                    <sup>$</sup>2.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <NavLink to="/plans-detail" className="custom-button">
                    Buy Now!
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="single-plan">
                  <p className="duration">Premium</p>
                  <h4 className="number">
                    <sup>$</sup>7.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <NavLink to="/plans-detail" className="custom-button">
                    Buy Now!
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-plans"></div>
      </section>
      {/* mobile view membership plan */}
      <section
        className="membership-section mobile-view"
        data-section
        id="membership"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="content">
                <div className="section-header">
                  <h6 className="sub-title extra-padding">
                    Upgrade Your Profile
                  </h6>
                  <h2>Premium Member Plan</h2>
                  <p className="text">
                    Benefit from Peyamba at its maximum you!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="priceee">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={2}
              // pagination={{ clickable: true }}
              navigation
              breakpoints={{
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="single-plan">
                  <p className="duration">1 Month</p>
                  <h4 className="number">
                    <sup>$</sup>4.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <a href="#" className="custom-button">
                    Buy Now!
                  </a>
                  <h6 className="planss" onClick={() => setLgShow(true)}>
                    Read More
                  </h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-plan">
                  <p className="duration">1 Month</p>
                  <h4 className="number">
                    <sup>$</sup>3.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <a href="#" className="custom-button">
                    Buy Now!
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-plan">
                  <p className="duration">1 Month</p>
                  <h4 className="number">
                    <sup>$</sup>2.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <a href="#" className="custom-button">
                    Buy Now!
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-plan">
                  <p className="duration">1 Month</p>
                  <h4 className="number">
                    <sup>$</sup>1.99
                  </h4>
                  <p className="stamet">Per Month</p>
                  <a href="#" className="custom-button">
                    Buy Now!
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="pricing-plans"></div>
      </section>
      {/* google rating section */}
      <section className="GoCashless">
        <Container>
          <Row className="market-exprties">
            <Col lg={4} md={6} sm={6}>
              <div className="marketing-expert">
                <ul>
                  <li>
                    <span>
                      <IoStar />
                    </span>
                  </li>
                  <li>
                    <span>
                      <IoStar />
                    </span>
                  </li>
                  <li>
                    <span>
                      <IoStar />
                    </span>
                  </li>
                  <li>
                    <span>
                      <IoStar />
                    </span>
                  </li>
                  <li>
                    <span>
                      <IoStarHalfOutline />
                    </span>
                  </li>
                </ul>
                <h2>
                  4.8<span>Google Rating</span>
                </h2>
                <p>
                  You find us, finally and you are already in love. More than
                  5.000.000 around the world already shared the same experience
                  andng ares uses our system Joining us today just got easier!
                </p>
                <NavLink to="/#" className="custom-button">
                  Read More
                </NavLink>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="marketing-expert-img">
                <img src={market} />
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="max-main">
                <div className="marketing-expert-2">
                  <h2>Marketing Experts</h2>
                  <p>Sed ut perspiciatis unde omnis iste natus erro</p>
                </div>
                <div className="has">
                  <img src={hash} />
                </div>
              </div>
              <div className="max1-main">
                <div className="marketing-expert-3">
                  <div className="marketing-expert-2">
                    <h2 className="text-white">Branding Experts</h2>
                    <p className="text-white">
                      Sed ut perspiciatis unde omnis iste natus erro
                    </p>
                  </div>
                </div>
                <div className="has">
                  <img src={has1} className="has1-0" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* testimonials  */}
      <section className="testimonial-section">
        <div className="large-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sec-title">
                  <span className="title">Customer Reviews</span>
                  <h2>What Our client say ?</h2>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            // navigation
            pagination={{ clickable: true }}
            //   scrollbar={{ draggable: true }}
            //   onSwiper={(swiper) => console.log(swiper)}
            //   onSlideChange={() => console.log('slide change')}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testimonial-block">
                  <div className="inner-box">
                    <p>{testimonial.text}</p>
                    <div className="info-box">
                      <div className="thumb">
                        <img src={testimonial.imgSrc} alt={testimonial.name} />
                      </div>
                      <h4 className="name">{testimonial.name}</h4>
                      <p>{testimonial.designation}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="thumb-layer paroller">
            <figure className="image">
              <img
                src="http://t.commonsupport.com/adro/images/resource/user-thumbs.png"
                alt="User Thumbs"
              />
            </figure>
          </div>
        </div>
      </section>
      {/* download section */}
      <section className="update-profile-section" data-section id="download">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="content-1">
                <div className="downloadedd">
                  <div className="section-header text-left">
                    <h6 className="sub-title extra-padding wow fadeInUp">
                      Easy Connect To Everyone
                    </h6>
                    <h2 className="mt-3">Download Our Apps</h2>
                    <p className="mt-3 mb-3">
                      Enhance your experience with our apps, available for
                      download from major app stores. Whether you're using an
                      iOS or Android device, you can easily find and install our
                      applications to access our services and features on the
                      go.
                    </p>
                  </div>
                </div>
                <div className="download">
                  <NavLink to="/#">
                    <div className="play">
                      <div className="play0">
                        {/* <h5>Google Play</h5> */}
                        <img src={aap1} />
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to="/#">
                    <div className="play" style={{ marginLeft: "10px" }}>
                      <div className="play0">
                        {/* <h5>App Store</h5> */}
                        <img src={aap2} />
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="aap-screen">
                {/* <img src={appall} className="isos"/> */}
                <img src={dono} className="androps" />
                {/* <img src={mocup}/> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* modal for price */}
    </>
  );
};

export default HomePage;
