import React, { Suspense } from "react";
import Loader from "./Loader";
import { useRoutes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy_Policy";
 import Termcondition from "./Pages/Term_Condition";
import Faq from "../src/Pages/Faq";
 import Testimonial from "./Pages/Testimonial";
 import MemberDetail from "./Pages/MemberDetail";
 
// import Termcondition from "./Pages/Term_Condition";
// import Faq from "./Pages/Faq";

export default function Router() {
  return (
    <Suspense fallback={<Loader />}>
      {useRoutes([
        { path: "/", element: <HomePage /> },
        { path: "/plans-detail", element: <MemberDetail /> },
        { path: "/registration", element: <Registration /> },
        { path: "/login", element: <Login /> },
        { path: "/contact", element: <Contact /> },
         { path: "/privacy-policy", element: <Privacy /> },
         { path: "/term-condition", element: <Termcondition /> },
         { path: "/faq", element: <Faq /> },
         { path: "/testimonial", element: <Testimonial /> },
        // { path: "/Faq", element: <Faq /> },
      ])}
    </Suspense>
  );
}
