import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CiCamera } from "react-icons/ci";
import "../assets/css/Ragister.css";
import register from "../assets/images/register.png";
import { BsEyeSlash, BsEye } from "react-icons/bs";

const Registration = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    phone: "",
    password: "",
    confirmPassword: "",
    bio: "",
    profilePic: null,
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, profilePic: e.target.files[0] });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const trimAndCheckWhitespace = (value, fieldName) => {
    if (!value.trim()) {
      return `${fieldName} is required`;
    } else if (value !== value.trim()) {
      return `Whitespace is not allowed in ${fieldName}`;
    } else if (fieldName === "Password" && value.length < 6) {
      return "Password must be at least 6 characters long";
    } else if (fieldName === "Confirm Password" && value !== formData.password) {
      return "Passwords do not match";
    }
    return '';
  };

  const validate = () => {
    const newErrors = {};

    // Validation rules
    const nameError = trimAndCheckWhitespace(formData.name, "User Name");
    if (nameError) {
      newErrors.name = nameError;
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      newErrors.name = "User Name can only contain letters and spaces";
    }

    const emailError = trimAndCheckWhitespace(formData.email, "Email");
    if (emailError) {
      newErrors.email = emailError;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    const dobError = trimAndCheckWhitespace(formData.dob, "Birthday");
    if (dobError) newErrors.dob = dobError;

    const phoneError = trimAndCheckWhitespace(formData.phone, "Mobile Number");
    if (phoneError) newErrors.phone = phoneError;

    const passwordError = trimAndCheckWhitespace(formData.password, "Password");
    if (passwordError) newErrors.password = passwordError;

    const confirmPasswordError = trimAndCheckWhitespace(formData.confirmPassword, "Confirm Password");
    if (confirmPasswordError) newErrors.confirmPassword = confirmPasswordError;

    const bioError = trimAndCheckWhitespace(formData.bio, "Short Bio");
    if (bioError) newErrors.bio = bioError;

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // console.log("Form Data:", formData);
      alert("Form submitted successfully!");
      // Implement form submission logic here, e.g., sending data to a server
    } else {
      console.log("Form contains errors. Please fix them and try again.");
    }
  };
  return (
    <section className="account-section bg_img">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="register-images-new">
              <img src={register} alt="Register" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="padding-top padding-bottom">
              <div className="account-area">
                <div className="row">
                  <div className="col-md-8">
                    <div className="login-header mt-3 mb-0">
                      <h3>Create Account</h3>
                      <p>
                        Let's create your profile! Just fill in the fields
                        below, and we’ll get a new account.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <form>
                      <div className="rounded-box">
                        <div className="outer">
                          <div className="inner">
                            <input
                              className="inputfile"
                              type="file"
                              name="pic"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                            <label>
                              <CiCamera />
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <form className="account-form mb-4" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          User Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter User Name"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <span className="error">{errors.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label>
                          Email<span>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label>
                          Birthday<span>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="dob"
                          name="dob"
                          value={formData.dob}
                          onChange={handleChange}
                        />
                        {errors.dob && (
                          <span className="error">{errors.dob}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Mobile Number<span>*</span>
                        </label>
                        <PhoneInput
                          country={"us"}
                          value={formData.phone}
                          onChange={(phone) =>
                            setFormData({ ...formData, phone })
                          }
                        />
                        {errors.phone && (
                          <span className="error">{errors.phone}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label>
                          Password<span>*</span>
                        </label>
                        <div className="password-input-container">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            id="pass1"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <BsEye /> : <BsEyeSlash />}
                          </div>
                        </div>
                        {errors.password && (
                          <span className="error">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label>
                          Confirm Password<span>*</span>
                        </label>
                        <div className="password-input-container">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Confirm Password"
                            id="pass2"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                          />
                          <div
                            className="password-toggle"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showPassword ? <BsEye /> : <BsEyeSlash />}
                          </div>
                        </div>
                        {errors.confirmPassword && (
                          <span className="error">
                            {errors.confirmPassword}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-4">
                        <label>
                          Short Bio<span>*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Short Bio"
                          id="short"
                          name="bio"
                          value={formData.bio}
                          onChange={handleChange}
                          style={{ height: "100px" }}
                        />
                        {errors.bio && (
                          <span className="error">{errors.bio}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-button mt-2">
                    <button type="submit" className="resiter">
                      Register
                    </button>
                  </div>
                </form>
                <div className="Already-text d-flex justify-content-center align-items-center">
                  <span>Already have an account?</span>{" "}
                  <NavLink to="/login" className="have-account ml-2">
                    Login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
