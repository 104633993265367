import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";
import "./privacy.css";
const Termcondition = () => {
  return (
    <>
      <Header />

      <div className="private">
        <div className="container">
          <div className="row">
            <div className="privacy-plocy">
              <h2> Term And Condition</h2>
              <h5>Meetups Fellow Terms of Use</h5>
              <p>
                California subscribers: You may cancel your subscription,
                without penalty or obligation, at any time prior to midnight of
                the third business day following the date you subscribed. If you
                subscribed using an External Service (e.g., Apple ID, Google
                Play), you must cancel through your External Service, as set
                forth in more detail in Section 8a. If you subscribed through
                your Apple ID, refunds are handled by Apple, not MeetupsFellow. You can
                request a refund from Apple through your Apple ID account on
                your phone or at https://getsupport.apple.com. All other users
                may request a refund by contacting MeetupsFellow Customer Service via
                our Help Center, or by mailing or delivering a signed and dated
                notice that states that you, the buyer, are canceling this
                agreement, or words of similar effect. Please also include your
                name and the email address, phone number, or other unique
                identifier you used to sign up for your account.
              </p>
              <h6>1. INTRODUCTION</h6>
              <p>
                By accessing or using MeetupsFellow's Services, you agree to be bound
                by this Terms of Use Agreement (the “Terms” or “Agreement”),
                including our Privacy Policy, Cookie Policy, Community
                Guidelines, and Safety Tips, so it is important that you read
                this Agreement and these policies and procedures carefully
                before you create an account.
              </p>
              <h6>2. ACCOUNT ELIGIBILITY; YOUR RESPONSIBILITIES</h6>
              <p>
                Before you create an account on MeetupsFellow, make sure you are
                eligible to use our Services. This Section also details what you
                can and can't do when using the Services, as well as the rights
                you grant MeetupsFellow. You are not authorized to create an account or
                use the Services unless all of the following are true, and by
                using our Services, you represent and warrant that:
              </p>
              <ul>
                <li>
                  <span className="p-policy">
                    You share information with other users when you voluntarily
                    disclose information on the service for others to see (e.g.,
                    your public profile).
                  </span>
                </li>
                <li>
                  <span className="p-policy">
                    We share information with vendors who help us operate our
                    service. They provide us services such as data hosting and
                    maintenance, customer care, and security operations.
                  </span>
                </li>
                <li>
                  <span className="p-policy">
                    We may share information with affiliates who assist us in
                    data processing operations, as service providers. This
                    assistance may include processing operations, such as data
                    hosting and maintenance, customer care, data security and
                    fighting against spam, abuse, fraud and other wrongdoings.
                  </span>
                </li>
                <li>
                  <span className="p-policy">
                    We may disclose your information if we are involved, whether
                    in whole or in part, in a merger, sale, acquisition,
                    divestiture, restructuring, reorganization, dissolution,
                    bankruptcy or other change of ownership or control.
                  </span>
                </li>
                <li>
                  <span className="p-policy">
                    We may disclose your data to: (i) comply with a legal
                    process, such as a court order, subpoena or search warrant,
                    government / law enforcement investigation or other legal
                    requirements; (ii) assist in the prevention or detection of
                    crime; (iii) protect the safety of any person; and (iv)
                    establish, exercise or defend legal claims.
                  </span>
                </li>
              </ul>
              <h6>3.MEMBER CONTENT</h6>
              <p>
                Other users will also share content on our Services. Member
                Content belongs to the user who posted the content and is stored
                on our servers and displayed at the direction of that user.
              </p>
              <p>
                You should always carefully review and independently verify
                Member Content for accuracy. Other users may use tools to
                generate or enhance content based on the Member Content they
                provide. Member Content may include biased, incorrect, harmful,
                offensive, or misleading information. Other users are
                responsible for their Member Content, as well as any and all
                decisions made, actions taken, and failures to take action based
                on their use of Member Content.
              </p>
              <h6>4.ACCOUNT TERMINATION</h6>
              <p>
                If you no longer wish to use our Services, or if we terminate
                your account for any reason, here's what you need to know. You
                can delete your account at any time by logging into the Website
                or App, going to "Settings", clicking "Delete Account", and
                following the instructions to complete the deletion process.
                However, you will need to cancel / manage any External Service
                Purchases through your External Service Account (e.g., iTunes,
                Google Play) to avoid additional billing.
              </p>
              <h6>5.REFUNDS</h6>
              <p>
                Your Right to Cancel - You may cancel your subscription, without
                penalty or obligation, at any time prior to midnight of the
                third business day following the date you subscribed. In the
                event that you die before the end of your subscription period,
                your estate shall be entitled to a refund of that portion of any
                payment you had made for your subscription which is allocable to
                the period after your death. In the event that you become
                disabled (such that you are unable to use our Services) before
                the end of your subscription period, you shall be entitled to a
                refund of that portion of any payment you had made for your
                subscription which is allocable to the period after your
                disability by providing the Company notice in the same manner as
                you request a refund as described below.
              </p>
              <p>
                If any of the above apply to you and you subscribed using your
                Apple ID, your refund requests are handled by Apple, not MeetupsFellow.
                To request a refund, please contact your External Service
                directly; for example using your Apple device, go to Settings 
                iTunes & App Stores  [click on your Apple ID]  View Apple ID 
                Purchase History. Find the transaction and select "Report a
                Problem." You can also request a refund at Apple Support. For
                any other purchase, please contact MeetupsFellow Customer Service with
                your order number (see your confirmation email) by mailing or
                delivering a signed and dated notice which states that you, the
                buyer, are canceling this Agreement, or words of similar effect.
                Please also include the email address or telephone number
                associated with your account along with your order number. This
                notice shall be sent to:
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Termcondition;
