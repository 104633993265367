import React, { useState } from "react";
import "../assets/css/Ragister.css";
import { NavLink } from "react-router-dom";
import register from "../assets/images/register.png";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { IoArrowBackOutline } from "react-icons/io5";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: value.trim() ? "" : "Email is required",
    }));
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setErrors({ ...errors, password: value.trim() ? "" : "Password is required" });
  };

  const validateForm = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    if (!password.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password is required",
      }));
      isValid = false;
    } else if (password.length < 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters long",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // console.log("Form Data:");
      alert("Form submitted successfully!");
      // Reset form fields
      setEmail("");
      setPassword("");
      setShowPassword(false);
      setErrors({ email: "", password: "" });
    } else {
      alert("Form contains errors. Please fix them and try again.");
    }
  };
  return (
    <section className="account-section bg_img">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="register-images-new">
              <img src={register} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="padding-top padding-bottom">
              <div className="account-area-login">
                <div className="login-header mb-3">
                  <h3>Welcome Back !</h3>
                  <p>To connected with us Please
                    login with your personal info
                  </p>
                </div>
                <form className="account-form login" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label>
                          Enter Your Email<span>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          // placeholder="Enter Your Email"
                          id="email1"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {errors.email && (
                          <p className="error">{errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label>
                          Enter Your Password<span>*</span>
                        </label>
                        <div className="password-input">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            // placeholder="Password"
                            id="pass1"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                          <i
                            className="password-icon"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <BsEye /> : <BsEyeSlash />}
                          </i>
                        </div>
                        {errors.password && (
                          <p className="error">{errors.password}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="remeber">
                        <input id="" name="" type="checkbox" value="yes" />
                        <label htmlFor="wp-comment-cookies-consent">
                          Remeber Me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="login-forgot">
                        <h6>Forgot Password</h6>
                      </div>
                    </div>
                  </div>
                  <div className="form-button-login">
                    <button
                      type="submit"
                      className="resiter"
                    >
                      Login
                    </button>
                  </div>
                </form>
                <div className="col-md-12">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="back-btn mr-3">
                      <NavLink to="/"><IoArrowBackOutline />Back</NavLink>
                    </div>
                    <div className="Already-text">
                      <span>Not a member ?</span>
                      <NavLink to="/registration" className="have-account ml-2">
                        Register
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
