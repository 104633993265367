import React from "react";
// import { NavLink } from "react-router-dom";
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";
import "./privacy.css";
const Privacy = () => {
  return (
    <>
      <Header />
      <div className="private">
        <div className="container">
          <div className="row">
            <div className="privacy-plocy">
              <h2> Privacy And Policy</h2>
              <h5>Consumer Health Data Privacy Policy</h5>
              <p>
                This Consumer Health Data Privacy Policy supplements our Privacy
                Policy and is intended only for residents of Washington and
                Nevada.
              </p>
              <h6>Collection and Use of Consumer Health Data </h6>
              <p>
                We do not believe we collect “consumer health data,” and we do
                not use data we collect to identify or make inferences about
                your past, present, or future physical or mental health status.
                Because “consumer health data” is defined so broadly in some
                jurisdictions, however, the information we may collect from or
                about you from time to time may be considered “consumer health
                data” under laws in those jurisdictions. Like other data we
                collect, the main reason we use this information is to provide
                our service to you and help you connect with other members.
              </p>
              <h6>How We Disclose Consumer Health Data</h6>
              <p>
                We may share consumer health data with the following categories
                of third parties when necessary for the purposes described
                above:
              </p>
              <ul>
                <li>
                  <span className="sap-privacy">Other users.</span>
                  <span className="p-policy">
                    You share information with other users when you voluntarily
                    disclose information on the service for others to see (e.g.,
                    your public profile).
                  </span>
                </li>
                <li>
                  <span className="sap-privacy">Service providers.</span>
                  <span className="p-policy">
                  We share information with vendors who help us operate our service. They provide us services such as data hosting and maintenance,
                   customer care, and security operations.
                  </span>
                </li>
                <li>
                  <span className="sap-privacy">Affiliates.</span>
                  <span className="p-policy">
                  We may share information with affiliates who assist us in data processing operations, as service providers. This assistance may include processing operations, such as data hosting and maintenance, customer care, data security and fighting 
                  against spam, abuse, fraud and other wrongdoings.
                  </span>
                </li>
                <li>
                  <span className="sap-privacy">Parties to a corporate transaction.</span>
                  <span className="p-policy">
                  We may disclose your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy
                   or other change of ownership or control.
                  </span>
                </li>
                <li>
                  <span className="sap-privacy">Law enforcement authorities / Legal Processes. </span>
                  <span className="p-policy">
                  We may disclose your data to: (i) comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) assist in the prevention or detection of crime; (iii) protect the safety of any person; and (iv)
                   establish, exercise or defend legal claims.
                  </span>
                </li>
              </ul>
              <h5>Your Rights</h5>
              <p>You can exercise your privacy rights under applicable law by submitting a request to our customer care team here. </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
