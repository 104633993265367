import React, { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegMap } from "react-icons/fa";
import "./Contact.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Contact1 = () => {
  // mobile number state
  const [mobilenumber, setmobilenumber] = useState("");
  const [valid, setvalid] = useState(false);
  const [mobilevalid, setmobilevalid] = useState(false);
  const [mobilevalidationerror, setmobilevalidationerror] = useState("");

  const mobilevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      setmobilevalidationerror("Please Enter A valid Mobile Number");
      setmobilevalid(false);
    } else {
      setmobilevalidationerror("");
      setmobilevalid(true);
    }
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showAlert = (message) => {
    Swal.fire({
      title: "Message Sent",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const handleSubmit = (values) => {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("subject", values.subject);
    bodyFormData.append("contact", mobilenumber);
    bodyFormData.append("message", values.message);
    const url = `${process.env.REACT_APP_BASE_URL}/contactus`;

    axios
      .post(url, bodyFormData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          showAlert(response.data.message);
          setmobilenumber("");
        } else if (response.status === 201) {
          showToastMessageone(response.data.message.name);
          showToastMessageone(response.data.message.email);
          showToastMessageone(response.data.message.subject);
          showToastMessageone(response.data.message.contact);
          showToastMessageone(response.data.message.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      contact: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      subject: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
      message: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <>
      <Header />
      <section className="contact" id="contact">
        <div className="container">
          <div className="row">
            <div className="contt">
              <div className="heading text-center">
                <h2>
                  Contact
                  <span> Us </span>
                </h2>
                <p>
                  Got something you want to talk about? Contact us or email us
                  and we promise to get back to you as soon as we can
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="title">
                <h3>Contact Details</h3>
                <p>
                  If you have any questions, comments, or suggestions, please
                  feel free to reach out to us using the form below. We value
                  your feedback and are committed to continually enhancing your
                  #Dating experience. We look forward to hearing from you!
                </p>
              </div>
              <div className="content">
                <div className="info">
                  <a href="tel:+ 91 9669646605">
                    <i>
                      <FiPhoneCall />
                    </i>
                    <div className="contac-innn">
                      <h4>PHONE :</h4>
                      <p>+91 9669646605</p>
                    </div>
                  </a>
                </div>
                <div className="info">
                  <a href="mailto:example@example.com">
                    <i>
                      <MdOutlineEmail />
                    </i>
                    <div className="contac-innn">
                      <h4>EMAIL :</h4>
                      <p>meetups@info.com</p>
                    </div>
                  </a>
                </div>
                <div className="info">
                  <i>
                    <FaRegMap />
                  </i>
                  <div className="contac-innn">
                    <h4>ADDRESS :</h4>
                    <p>6743 last street , Abcd, Xyz</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="name" className="contact-label">
                      Enter Name
                    </label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="off"
                        className="form-control"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <p className="errormessage">{formik.errors.name}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="contact-label">
                      Enter Email
                    </label>
                    <Form.Group>
                      <Form.Control
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        className="form-control"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p className="errormessage">{formik.errors.email}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="phone" className="contact-label">
                      Enter Mobile Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      // onlyCountries={["in"]}
                      value={mobilenumber}
                      Dropdown
                      countryCodeEditable={true}
                      onChange={(phone, value) => {
                        setmobilenumber(phone);
                        mobilevalidation(phone);
                      }}
                      prefix="+"
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        required: true,
                        autoFocus: true,
                        autoComplete: "off",
                      }}
                      isValid={(validmobilenumber) => {
                        if (
                          !new RegExp(/^[0-9+].{6,12}$/).test(validmobilenumber)
                        ) {
                          setvalid(false);

                          return false;
                        } else {
                          setvalid(true);

                          return true;
                        }
                      }}
                    />
                    <span>
                      {mobilevalid ? (
                        <></>
                      ) : (
                        <>
                          <p className="errormessage">
                            {mobilevalidationerror}
                          </p>
                        </>
                      )}
                    </span>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="subject" className="contact-label">
                      Enter Subject
                    </label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="subject"
                        id="subject"
                        autoComplete="off"
                        className="form-control"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.subject && formik.touched.subject && (
                        <p className="errormessage">{formik.errors.subject}</p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="message" className="contact-label">
                      Enter Message
                    </label>
                    <Form.Control
                      as="textarea"
                      style={{ height: "108px" }}
                      type="message"
                      name="message"
                      id="message"
                      autoComplete="off"
                      className="form-control"
                      // multiline
                      rows={4}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.message && formik.touched.message && (
                      <p className="errormessage">{formik.errors.message}</p>
                    )}
                  </div>
                </div>
                <button className="btn btn-block" type="submit">
                  SEND
                </button>
              </Form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact1;
