import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";

const MemberDetails = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 640);

  const updateView = () => {
    setIsDesktop(window.innerWidth > 640);
  };

  useEffect(() => {
    // Update the view on component mount
    updateView();
    // Add event listener for window resize
    window.addEventListener("resize", updateView);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateView);
  }, []);

  // tabcode

  const [key, setKey] = useState("Free");

  return (
    <>
      {isDesktop ? (
        <>
          <Header />
          <div className="plan-new web-view">
            <section className="pt-2 pb-3">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h6 className="benift-text">
                      Benefits of a membership plan :
                    </h6>
                    <table className="table-responsive table-borderless">
                      <thead>
                        <tr>
                          {/* <th scope="col">Premimum features</th> */}
                          <th
                            scope="col"
                            className="gold-premum-plas text-white p-2  border"
                          >
                            Features
                          </th>
                          <th
                            scope="col"
                            className="gold-premum-plas text-white p-2  border"
                          >
                            Free
                          </th>
                          <th
                            scope="col"
                            className="gold-premum-plas text-white p-2  border"
                          >
                            Special park &nbsp; ($1.9)
                          </th>
                          <th
                            scope="col"
                            className="gold-premum-plas text-white p-2  border"
                          >
                            Standard park &nbsp; ($2.99)
                          </th>
                          <th
                            scope="col"
                            className="gold-premum-plas text-white p-2  border"
                          >
                            Premium &nbsp; ($7.99)
                          </th>
                          {/* <th
                            scope="col"
                            className="gold-premum-plas text-white p-2  border"
                          >
                            Featured
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="bg-white p-2  border">Bedge</td>
                          <td className="bg-white p-2  border">N/A</td>
                          <td className="bg-white p-2  border">N/A</td>
                          <td className="bg-white p-2  border">N/A</td>
                          <td className="bg-white p-2  border">
                            Premium bedge on profile
                          </td>
                          {/* <td className="bg-white p-2  border">
                            Featured bedge on profile
                          </td> */}
                        </tr>
                        <tr>
                          <td className="bg-white p-2  border">Google Ads</td>
                          <td className="bg-white p-2  border">
                            Contains Google ads
                          </td>
                          <td className="bg-white p-2  border">Ads Free App</td>
                          <td className="bg-white p-2  border">Ads Free App</td>
                          <td className="bg-white p-2  border">Ads Free App</td>
                          {/* <td className="bg-white p-2  border">Ads Free App</td> */}
                        </tr>
                        <tr>
                          <td className="bg-white p-2  border">
                            Load Profiles
                          </td>
                          <td className="bg-white p-2  border">
                            Only 100 Nearby Profiles
                          </td>
                          <td className="bg-white p-2  border">No limits</td>
                          <td className="bg-white p-2  border">No limits</td>
                          <td className="bg-white p-2  border">No limits</td>
                          {/* <td className="bg-white p-2  border">No limits</td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">Profile</td>
                          <td className="bg-white p-2  border">
                            Always Public
                          </td>
                          <td className="bg-white p-2  border">
                            Always Public
                          </td>
                          <td className="bg-white p-2  border">
                            Can make Private
                          </td>
                          <td className="bg-white p-2  border">
                            Can make Private and hidden
                          </td>
                          {/* <td className="bg-white p-2  border">
                            Can make private and hidded, List up in featured
                            member
                          </td> */}
                        </tr>
                        <tr>
                          <td className="bg-white p-2  border">
                            Who Viewed Profile
                          </td>
                          <td className="bg-white p-2  border">Hidden</td>
                          <td className="bg-white p-2  border">Hidden</td>
                          <td className="bg-white p-2  border">Can see</td>
                          <td className="bg-white p-2  border">Can see</td>
                          {/* <td className="bg-white p-2  border">Can see</td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Filters Profile
                          </td>
                          <td className="bg-white p-2  border">No Filter</td>
                          <td className="bg-white p-2  border">
                            Basic Filters (Gender, Location, Name)
                          </td>
                          <td className="bg-white p-2  border">
                            Advanced Filters (Gender, Location, Name, and all
                            other preferences)
                          </td>
                          <td className="bg-white p-2  border">
                            Advanced Filters (Gender, Location, Name, and all
                            other preferences)
                          </td>
                          {/* <td className="bg-white p-2  border">
                            Advanced Filters (Gender, Location, Name, and all
                            other preferences)
                          </td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Connection Type
                          </td>
                          <td className="bg-white p-2  border">
                            Only with connection request
                          </td>
                          <td className="bg-white p-2  border">
                            Only with connection request
                          </td>
                          <td className="bg-white p-2  border">
                            With direct message note (25/month)
                          </td>
                          <td className="bg-white p-2  border">
                            with direct messages note (50/month)
                          </td>
                          {/* <td className="bg-white p-2  border">
                            with direct message note (no limit)
                          </td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Media Ablum Type
                          </td>
                          <td className="bg-white p-2  border">
                            Can upload only images
                          </td>
                          <td className="bg-white p-2  border">
                            Can upload only images
                          </td>
                          <td className="bg-white p-2  border">
                            Can upload images and videos
                          </td>
                          <td className="bg-white p-2  border">
                            images and videos orgnised in folder
                          </td>
                          {/* <td className="bg-white p-2  border">
                            images and videos and Organiseed in folders
                          </td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">Media Access</td>
                          <td className="bg-white p-2  border">Public</td>
                          <td className="bg-white p-2  border">
                            Can Make Private and limited
                          </td>
                          <td className="bg-white p-2  border">
                            Can Make Private and limited
                          </td>
                          <td className="bg-white p-2  border">
                            Can Make Private and limited
                          </td>
                          {/* <td className="bg-white p-2  border">
                            Can make private and limited
                          </td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Media Storage
                          </td>
                          <td className="bg-white p-2  border">1 GB</td>
                          <td className="bg-white p-2  border">5 GB</td>
                          <td className="bg-white p-2  border">10 GB</td>
                          <td className="bg-white p-2  border">25 GB</td>
                          {/* <td className="bg-white p-2  border">50 GB</td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">Chat History</td>
                          <td className="bg-white p-2  border">
                            Limited History (upto 72 hrs)
                          </td>
                          <td className="bg-white p-2  border">no limit</td>
                          <td className="bg-white p-2  border">no limit</td>
                          <td className="bg-white p-2  border">no limit</td>
                          {/* <td className="bg-white p-2  border">no limit</td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">Chat backup</td>
                          <td className="bg-white p-2  border">N/A</td>
                          <td className="bg-white p-2  border">N/A</td>
                          <td className="bg-white p-2  border">Available</td>
                          <td className="bg-white p-2  border">Available</td>
                          {/* <td className="bg-white p-2  border">Available</td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Chat Messages Types (Group&one-one)
                          </td>
                          <td className="bg-white p-2  border">
                            Text, images and emoji`s
                          </td>
                          <td className="bg-white p-2  border">
                            Text, images and emoji`s
                          </td>
                          <td className="bg-white p-2  border">
                            Text, images, Emoji`s, gifs, files
                          </td>
                          <td className="bg-white p-2  border">
                            Text, images, Emoji`s, gifs, Audios, Videos, Files,
                            location, with message life span
                          </td>
                          {/* <td className="bg-white p-2  border">
                            Text, images, Emoji`s, gifs, Audios, Videos, Files,
                            location, with message life span
                          </td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Meetups Events
                          </td>
                          <td className="bg-white p-2  border">Only View</td>
                          <td className="bg-white p-2  border">Only View</td>
                          <td className="bg-white p-2  border">
                            can add 1 Events/Months
                          </td>
                          <td className="bg-white p-2  border">
                            can add upto 5 Events/Month
                          </td>
                          {/* <td className="bg-white p-2  border">
                            can add upto 10 Events/Month
                          </td> */}
                        </tr>

                        <tr>
                          <td className="bg-white p-2  border">
                            Device Access
                          </td>
                          <td className="bg-white p-2  border">
                            Single device session
                          </td>
                          <td className="bg-white p-2  border">
                            Single device session
                          </td>
                          <td className="bg-white p-2  border">
                            2 device session at a time
                          </td>
                          <td className="bg-white p-2  border">
                            5 device session at a time
                          </td>
                          {/* <td className="bg-white p-2  border">no limit</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <div className="plan-new mobile-view">
          <h6 className="benift-text">Benefits of a membership :</h6>
          <div className="container">
            <Tabs
              defaultActiveKey="Free"
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="Free" title="Free">
                <div className="members-details-mobile">
                  <table className="table-responsive table-borderless">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Features
                        </th>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Availability
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-white p-2  border">Bedge</td>
                        <td className="bg-white p-2  border">N/A</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Google Ads</td>
                        <td className="bg-white p-2  border">
                          Contains Google ads
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Load Profiles</td>
                        <td className="bg-white p-2  border">
                          Only 100 Nearby Profiles
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Profile</td>
                        <td className="bg-white p-2  border">Always Public</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">
                          Who Viewed Profile
                        </td>
                        <td className="bg-white p-2  border">Hidden</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Filters Profile
                        </td>
                        <td className="bg-white p-2  border">No Filter</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Connection Type
                        </td>
                        <td className="bg-white p-2  border">
                          Only with connection request
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Media Ablum Type
                        </td>
                        <td className="bg-white p-2  border">
                          Can upload only images
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Access</td>
                        <td className="bg-white p-2  border">Public</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Storage</td>
                        <td className="bg-white p-2  border">1 GB</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat History</td>
                        <td className="bg-white p-2  border">
                          Limited History (upto 72 hrs)
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat backup</td>
                        <td className="bg-white p-2  border">N/A</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Chat Messages Types (Group&one-one)
                        </td>
                        <td className="bg-white p-2  border">
                          Text, images and emoji`s
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Meetups Events</td>
                        <td className="bg-white p-2  border">Only View</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Device Access</td>
                        <td className="bg-white p-2  border">
                          Single device session
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="Spacial" title="Spacial park 1.9">
                <div className="members-details-mobile">
                  <table className="table-responsive table-borderless">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Features
                        </th>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Availability
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-white p-2  border">Bedge</td>
                        <td className="bg-white p-2  border">N/A</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Google Ads</td>
                        <td className="bg-white p-2  border">Ads Free App</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Load Profiles</td>
                        <td className="bg-white p-2  border">No limits</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Profile</td>
                        <td className="bg-white p-2  border">Always Public</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">
                          Who Viewed Profile
                        </td>
                        <td className="bg-white p-2  border">Hidden</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Filters Profile
                        </td>
                        <td className="bg-white p-2  border">
                          Basic Filters (Gender, Location, Name)
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Connection Type
                        </td>
                        <td className="bg-white p-2  border">
                          Only with connection request
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Media Ablum Type
                        </td>
                        <td className="bg-white p-2  border">
                          Can upload only images
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Access</td>
                        <td className="bg-white p-2  border">
                          Can Make Private and limited
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Storage</td>
                        <td className="bg-white p-2  border">5 GB</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat History</td>
                        <td className="bg-white p-2  border">no limit</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat backup</td>
                        <td className="bg-white p-2  border">N/A</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Chat Messages Types (Group&one-one)
                        </td>
                        <td className="bg-white p-2  border">
                          Text, images and emoji`s
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Meetups Events</td>
                        <td className="bg-white p-2  border">Only View</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Device Access</td>
                        <td className="bg-white p-2  border">
                          Single device session
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="Standard" title="Standard park 2.99">
                <div className="members-details-mobile">
                  <table className="table-responsive table-borderless">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Features
                        </th>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Availability
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-white p-2  border">Bedge</td>
                        <td className="bg-white p-2  border">N/A</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Google Ads</td>
                        <td className="bg-white p-2  border">Ads Free App</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Load Profiles</td>
                        <td className="bg-white p-2  border">No limits</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Profile</td>
                        <td className="bg-white p-2  border">
                          Can make Private
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">
                          Who Viewed Profile
                        </td>
                        <td className="bg-white p-2  border">Can see</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Filters Profile
                        </td>
                        <td className="bg-white p-2  border">
                          Advanced Filters (Gender, Location, Name, and all
                          other preferences)
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Connection Type
                        </td>
                        <td className="bg-white p-2  border">
                          With direct message note (25/month)
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Media Ablum Type
                        </td>
                        <td className="bg-white p-2  border">
                          Can upload images and videos
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Access</td>
                        <td className="bg-white p-2  border">
                          Can Make Private and limited
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Storage</td>
                        <td className="bg-white p-2  border">10 GB</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat History</td>
                        <td className="bg-white p-2  border">no limit</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat backup</td>
                        <td className="bg-white p-2  border">Available</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Chat Messages Types (Group&one-one)
                        </td>
                        <td className="bg-white p-2  border">
                          Text, images, Emoji`s, gifs, files
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Meetups Events</td>
                        <td className="bg-white p-2  border">
                          can add 1 Events/Months
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Device Access</td>
                        <td className="bg-white p-2  border">
                          2 device session at a time
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="Premium" title="Premium 7.99">
                <div className="members-details-mobile">
                  <table className="table-responsive table-borderless">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Features
                        </th>
                        <th
                          scope="col"
                          className="gold-premum-plas text-white p-2  border"
                        >
                          Availability
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-white p-2  border">Bedge</td>
                        <td className="bg-white p-2  border">
                          Premium bedge on profile
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Google Ads</td>
                        <td className="bg-white p-2  border">Ads Free App</td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">Load Profiles</td>
                        <td className="bg-white p-2  border">No limits</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Profile</td>
                        <td className="bg-white p-2  border">
                          Can make Private and hidden
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-white p-2  border">
                          Who Viewed Profile
                        </td>
                        <td className="bg-white p-2  border">Can see</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Filters Profile
                        </td>
                        <td className="bg-white p-2  border">
                          Advanced Filters (Gender, Location, Name, and all
                          other preferences)
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Connection Type
                        </td>
                        <td className="bg-white p-2  border">
                          with direct messages note (50/month)
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Media Ablum Type
                        </td>
                        <td className="bg-white p-2  border">
                          images and videos orgnised in folder
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Access</td>
                        <td className="bg-white p-2  border">
                          Can Make Private and limited
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Media Storage</td>
                        <td className="bg-white p-2  border">25 GB</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat History</td>
                        <td className="bg-white p-2  border">no limit</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Chat backup</td>
                        <td className="bg-white p-2  border">Available</td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">
                          Chat Messages Types (Group&one-one)
                        </td>
                        <td className="bg-white p-2  border">
                          Text, images, Emoji`s, gifs, Audios, Videos, Files,
                          location, with message life span
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Meetups Events</td>
                        <td className="bg-white p-2  border">
                          can add upto 5 Events/Month
                        </td>
                      </tr>

                      <tr>
                        <td className="bg-white p-2  border">Device Access</td>
                        <td className="bg-white p-2  border">
                          5 device session at a time
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};
export default MemberDetails;
