import React, { useState} from "react";
import { Form } from "react-bootstrap";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import ScrollToTop from "react-scroll-to-top";
import { FiInstagram } from "react-icons/fi";
import { FaFacebookF, FaDribbble, FaTwitter } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { FaRegMap } from "react-icons/fa";
import footerlogo from "../../assets/images/footerlogo.png";
import "./Footer.css";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email
    if (!email) {
      setError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setError("Invalid email format");
      return;
    }

    try {
      const response = await fetch(
        "https://demo.meetupsfellow.com/api/newsletter",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        setSuccessMessage("Thank you for subscribing!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
        setEmail(""); // Clear email input
        setError(""); // Clear error message
      } else {
        setError("Error submitting form. Please try again.");
      }
    } catch (error) {
      setError("Error submitting form. Please try again.");
      console.error("Error submitting form", error);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email) && !/\s/.test(email);
  };

  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="footer-contact">
                <div className="footer-logo">
                  <img src={footerlogo} alt="Logo" />
                </div>
                <ul>
                  <li className="fot-set">
                    <a href="tel:9009631314">
                      <FiPhoneCall className="social-icon" />{" "}
                      <span className="fot-set-u">+91 9009631314 </span>
                    </a>
                  </li>
                  <li className="fot-set">
                    <a href="mailto:rashmi.impetrosys@gmail.com">
                      <FiMail className="social-icon" />{" "}
                      <span  className="fot-set-u">meetups@gmail.com </span>
                    </a>
                  </li>
                  <li>
                    <FaRegMap className="social-icon" /> <span>indore</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="link-wrapper one">
                <h4 className="f-l-title">Our Information</h4>
                <ul className="f-solial-links">
                  <li>
                    <NavLink to="/contact">
                      <IoIosArrowForward /> Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://demo.meetupsfellow.com/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoIosArrowForward /> FAQ
                    </a>
                  </li>
                  {/* <li>
                    <NavLink to="/testimonial">
                      <IoIosArrowForward /> Customer Reviews
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="link-wrapper three">
                <h4 className="f-l-title">help center</h4>
                <ul className="f-solial-links">
                  <li>
                    {/* <NavLink to="/privacy-policy">
                      <IoIosArrowForward /> Privacy & Policy
                    </NavLink> */}
                    <a
                      href="https://demo.meetupsfellow.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoIosArrowForward /> Privacy & Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://demo.meetupsfellow.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoIosArrowForward />
                      Terms & Condition
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-6 col-12">
              <div className="social-media">
                <h4 className="f-l-title">Newsletter</h4>
                <div className="social-inputs">
                  <Form onSubmit={handleSubmit}>
                    <div className="email-subs">
                    <Form.Group>
                      <Form.Control
                        placeholder="Email Your Address"
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        className="social-form"
                        value={email}
                        onChange={handleChange}
                      />
                      {error && <p className="errormessage">{error}</p>}
                      {successMessage && (
                        <p className="success-message">{successMessage}</p>
                      )}
                       </Form.Group>
                      <button type="submit" className="footer-btn">
                        Subscribe
                      </button>
                    </div>
                  </Form>
                </div>
                <p>Get the latest news & updates</p>
                <ul className="footer-social-links">
                  <li>
                    <a href="https://x.com/MeetupsFellow" target="_blank" rel="noopener noreferrer">x</a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/MeetUpsFellow" target="_blank" rel="noopener noreferrer">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/meetupsfellow/" target="_blank" rel="noopener noreferrer">
                      <FiInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <FaDribbble />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <hr className="hr2" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 align-self-center">
                <div className="copyr-text">
                  <span>
                    Copyright © {new Date().getFullYear()} .All Rights Reserved
                    By
                  </span>
                  <a href="https://impetrosys.com/" target="_blank">
                    Impetrosys
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTop smooth />
    </>
  );
};

export default Footer;
